.admin-dashboard {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.admin-login {
    max-width: 400px;
    margin: 100px auto;
    padding: 20px;
    text-align: center;
}

.admin-login input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
}

.user-stats table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background: white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.user-stats th,
.user-stats td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #eee;
}

.user-stats th {
    background: #f5f5f5;
    font-weight: bold;
}

.queries-list {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.query-card {
    background: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.query-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #666;
    font-size: 0.9em;
}

.query-content {
    margin-top: 10px;
}

.query-content p {
    margin: 8px 0;
    line-height: 1.4;
}

.question {
    color: #2c3e50;
}

.answer {
    color: #34495e;
    white-space: pre-wrap;
}

.error {
    color: red;
    margin-top: 10px;
}

.password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.password-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.password-input {
    padding: 8px;
    font-size: 16px;
}

.submit-button {
    padding: 8px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #0056b3;
}

.loading, .error {
    text-align: center;
    padding: 20px;
    font-size: 1.2em;
}

.error {
    color: red;
} 