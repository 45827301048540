.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.google-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 1em;
  border: 1px solid #747775;
  border-radius: 4px;
  background-color: #fff;
  color: #1f1f1f;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 0.25px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

.google-btn:hover {
  background-color: #f7f7f7;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15); */
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.google-icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  background-image: url('https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/2048px-Google_%22G%22_logo.svg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.query-field {
  width: 95%;
  margin-bottom: 10px;
  padding: 14px;
  border:1px solid #dfe1e5;
  border-radius: 10px;
  font-size: 13px;
  outline: none;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.query-field:hover {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
  border-color: rgba(223, 225, 229, 0);
}

/* Main buttons */
.main-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 10px 15px;
  background-color: #e1f679; /* Light green background */
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  color: black; /* Text color */
  margin-bottom: '10px',
}

.main-button:hover {
  /* background-color: #d9df70; */ /* Slightly darker green on hover */
  filter: brightness(0.95); 
}

.main-button.inactive {
  background-color: #e0e0e0; /* Light gray background */
  cursor: pointer;
  opacity: 0.6;
}

.main-button.inactive:hover {
  background-color: #d0d0d0; /* Slightly darker gray on hover */
}

.main-button.disabled {
  background-color: #e0e0e0; /* Light gray background */
  cursor: not-allowed;
  opacity: 0.6;
}

/* Feedback container */
.feedback-container {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 1000;
}

/* Feedback button */
.feedback-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background-color: #e1f679; /* Light green background */
  border: none;
  border-radius: 24px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  color: black; /* Text color */
}

.feedback-button:hover {
  background-color: #d9df70; /* Slightly darker green on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Feedback icon and text */
.feedback-text {
  margin-left: 8px;
  font-weight: bold;
  color: black; /* Match button text color */
}

/* Feedback form */
.feedback-form {
  position: fixed;
  bottom: 80px;
  right: 16px;
  background-color: white; /* White background */
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  width: 300px;
  z-index: 1001;
  color: black; /* Text color */
}

.feedback-form textarea {
  width: 100%;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 8px;
  resize: none;
  font-size: 14px;
  color: black; /* Input text color */
  background-color: #f9f9f9; /* Light gray background */
}

.feedback-form textarea:focus {
  outline: none;
  border-color: #e1f679; /* Highlight border on focus */
  box-shadow: 0 0 4px rgba(225, 246, 121, 0.8);
}

.feedback-form button {
  background-color: #e1f679; /* Light green background */
  border: none;
  color: black;
  font-weight: bold;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.feedback-form button:hover {
  background-color: #d9df70; /* Slightly darker green on hover */
}

.feedback-message {
  margin-top: 8px;
  color: #333; /* Neutral gray for message */
  font-size: 14px;
}

.gradient-text {
  font-size: 48px; /* Adjust as needed */
  font-weight: bold;
  background: linear-gradient(90deg, #000, #000, #AB5898, #5C4BD3); /* Gradient colors */
  -webkit-background-clip: text; /* Clip the background to the text */
  background-clip: text;
  -webkit-text-fill-color: transparent; /* Make the text transparent to show the gradient */
  text-align: center;
}

.gradient-text-dark {
  font-size: 48px; /* Adjust as needed */
  font-weight: bold;
  background: linear-gradient(90deg, #fff, #fff, #AB5898, #5C4BD3); /* Gradient colors */
  -webkit-background-clip: text; /* Clip the background to the text */
  background-clip: text;
  -webkit-text-fill-color: transparent; /* Make the text transparent to show the gradient */
  text-align: center;
}

@media screen and (max-width: 700px) {
  .gradient-text {
    font-size: 40px; /* Smaller size for mobile screens */
  }
  
  /* If you also want to adjust the h3 elements inside gradient-text */
  .gradient-text h3 {
    font-size: 40px;
  }
}

/*Jumbo animated background https://codepen.io/piut0/pen/dyaezEN */

@keyframes jumbo {
  from {
      background-position: 50% 50%, 50% 50%;
  }
  to {
      background-position: 350% 50%, 350% 50%;
  }
}

.jumbo {
  --stripes: repeating-linear-gradient(
      100deg,
      #fff 0%,
      #fff 7%,
      transparent 10%,
      transparent 12%,
      #fff 16%
  );
  --stripesDark: repeating-linear-gradient(
      100deg,
      #000 0%,
      #000 7%,
      transparent 10%,
      transparent 12%,
      #000 16%
  );
  --rainbow: repeating-linear-gradient(
      100deg,
      #60a5fa 10%,
      #e879f9 15%,
      #60a5fa 20%,
      #5eead4 25%,
      #60a5fa 30%
  );
  background-image: var(--stripes), var(--rainbow);
  background-size: 300%, 200%;
  background-position: 50% 50%, 50% 50%;

  filter: invert(100%);

  mask-image: radial-gradient(ellipse at 100% 0%, black 40%, transparent 70%);

  pointer-events: none;
}

/* Safari-specific styles */
@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
      .jumbo {
          /* Simplified version for Safari */
          background-image: radial-gradient(
              ellipse at 100% 0%,
              rgba(96, 165, 250, 0.5) 0%,
              rgba(232, 121, 249, 0.3) 50%,
              transparent 70%
          );
          animation: none;
          filter: none;
          mask-image: none;
      }
      
      .jumbo::after {
          display: none;
      }
  }
}

/* Pausing the animation for now */
.jumbo::after {
  content: "";
  position: absolute;
  inset: 0;
  background-image: var(--stripes), var(--rainbow);
  background-size: 200%, 100%;
  animation: jumbo 60s linear infinite;
  background-attachment: fixed;
  mix-blend-mode: difference;
}

.shimmer {
  display: inline-block;
  color:white;
  font-size: small;
  height: 30px;
  
  /* background: #acacac -webkit-gradient(linear, 100% 0, 0 0, from(#acacac), color-stop(0.5, #ffffff), to(#acacac)); */
  background: #acacac -webkit-gradient(linear, 100% 0, 0 0, from(#000), color-stop(0.5, #AB5898), to(#5C4BD3));

  background-position: -4rem top; /*50px*/
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation-name: shimmer;
  animation: shimmer 1.5s infinite;
  animation-name: shimmer;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-background-size: 4rem 100%; /*50px*/
  background-size: 4rem 100%; /*50px*/
}

.typing-shimmer {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 15px;
  background: #000 -webkit-gradient(linear, 80% 0, 0 0, from(#000), color-stop(0.5, #AB5898), to(#5C4BD3));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shimmer 1.5s infinite;
}


.shimmer span {
  background: linear-gradient(
    90deg,
    /* #f0f0f0 25%,
    #e0e0e0 50%,
    #f0f0f0 75%, */
    #000 25%,
    #AB5898 50%,
    #5C4BD3 75%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  padding: 10px 20px;
  border-radius: 8px;
}

@keyframes shimmer {
  0% {
      background-position: -4rem top; /*50px*/
  }

  70% {
      background-position: 12.5rem top; /*200px*/
  }

  100% {
      background-position: 12.5rem top; /*200px*/
  }
}


.card {
  min-width: 100vw;
  min-height: 100vh; 
}

.card--4 {
  background: linear-gradient(124.14deg,rgba(255,252,243,0) 20%,rgba(255,252,243,0.2) 39.82%,rgba(255,252,243,0) 60%),radial-gradient(154% 102% at 85% 32.47%,rgba(255,252,243,0) 49.82%,rgba(230,148,247,0.6) 82.11%),radial-gradient(124% 129% at 35% 78.47%,rgba(255,252,243,0) 49.82%,rgba(255,252,243,0.6) 82.11%),radial-gradient(125% 178% at 25% 4.47%,rgba(255,252,243,0) 49.82%,rgba(255,252,243,0.6) 82.11%),radial-gradient(41.58% 150.68% at 26.01% 45.34%,rgba(255,252,243,0.6) 19.17%,rgba(255,252,243,0) 100%),linear-gradient(84.14deg,rgba(230,148,247,0.2) 2.84%,rgba(247,161,166,0.2) 118.515%),radial-gradient(50% 120% at 100% 14.47%,rgba(175,155,241,0) 6.9%,rgba(230,195,255,0.5) 52.84%,rgba(175,155,241,0) 100%),radial-gradient(56% 120% at 90% 112.47%,rgba(255,155,130,0) 26.9%,rgba(202,236,189,0.9) 72.84%,rgba(255,155,130,0) 100%),radial-gradient(35% 68% at 35% 84.47%,#ffaf9b 49.82%,rgba(251,211,199,0) 82.11%),linear-gradient(84.14deg,#bedbfa 2.84%,#caecbd 118.515%)
}

.card--44 {
  background: linear-gradient(124.14deg,rgba(255,252,243,0) 20%,rgba(255,252,243,0.2) 39.82%,rgba(255,252,243,0) 60%),radial-gradient(154% 102% at 85% 32.47%,rgba(255,252,243,0) 49.82%,rgba(230,148,247,0.6) 82.11%),radial-gradient(124% 129% at 35% 78.47%,rgba(255,252,243,0) 49.82%,rgba(255,252,243,0.6) 82.11%),radial-gradient(125% 178% at 25% 4.47%,rgba(255,252,243,0) 49.82%,rgba(255,252,243,0.6) 82.11%),radial-gradient(41.58% 150.68% at 26.01% 45.34%,rgba(255,252,243,0.6) 19.17%,rgba(255,252,243,0) 100%),linear-gradient(84.14deg,rgba(230,148,247,0.2) 2.84%,rgba(247,161,166,0.2) 118.515%),radial-gradient(50% 120% at 100% 14.47%,rgba(175,155,241,0) 6.9%,rgba(230,195,255,0.5) 52.84%,rgba(175,155,241,0) 100%),radial-gradient(56% 120% at 90% 112.47%,rgba(255,155,130,0) 26.9%,rgba(202,236,189,0.9) 72.84%,rgba(255,155,130,0) 100%),radial-gradient(35% 68% at 35% 84.47%,#ffaf9b 49.82%,rgba(251,211,199,0) 82.11%),linear-gradient(84.14deg,#bedbfa 2.84%,#caecbd 118.515%);
  padding:25px
}

.modal {
  /* left: 50%;
  top: 50%; */
  /* transform: translate(-50%,-50%); */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-self: center;
  gap: 36px;
  width: 380px;
  padding: 24px;
  border-radius: 40px;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.2) 100%);
  box-shadow: 0 0 16px 0 rgba(0,0,0,0);
  transition: 0.4s ease height, 0.4s ease padding, 0.2s border-radius, 0.6s ease box-shadow;
}
.modal:hover {
  box-shadow: 0 24px 48px -24px rgba(0,0,0,0.025);
}

.modal.active {
  height: 160px;
  padding: 32px;
  border-radius: 48px;
}

.field-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.field {
  font-family: "Instrument Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  /*   font-variation-settings: "wdth" 100; */
  font-size: 20px;
  display: flex;
  align-items: center;
  min-height: 48px;
  border-radius: 16px;
  border: 1px solid rgba(0,0,0,0.05);
  padding: 0 12px 2px 12px;
  background: none;
  color: #000;
  outline: none;
  transition: border 0.2s;
}
.active .field {
  border: 1px solid rgba(0,0,0,0.5);
}

.field::placeholder {
  opacity: 0.5;
  color: #000;
}


/*waitlist button animatio*/
:root {
  --glow-hue: 222deg;
  --shadow-hue: 180deg;
  --spring-easing: linear(
  0, 0.002, 0.01 0.9%, 0.038 1.8%, 0.156, 0.312 5.8%, 0.789 11.1%, 1.015 14.2%,
  1.096, 1.157, 1.199, 1.224 20.3%, 1.231, 1.231, 1.226, 1.214 24.6%,
  1.176 26.9%, 1.057 32.6%, 1.007 35.5%, 0.984, 0.968, 0.956, 0.949 42%,
  0.946 44.1%, 0.95 46.5%, 0.998 57.2%, 1.007, 1.011 63.3%, 1.012 68.3%,
  0.998 84%, 1
);
  --spring-duration: 1.33s;
}

@property --waitlist-shimmer {
  syntax: "<angle>";
  inherits: false;
  initial-value: 33deg;
}

@keyframes waitlist-shimmer {
  0% {
      --waitlist-shimmer: 0deg;
  }
  100% {
      --waitlist-shimmer: 360deg;
  }
}

@keyframes shine {
  0% {
      opacity: 0;
  }
  15% {
      opacity: 1;
  }
  55% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}
@keyframes waitlist-text {
  0% {
      background-position: 100% center;
  }    
  100% {
      background-position: -100% center;
  }    
}

.waitlist-button {
  color: var(--bg);
  font-weight: 600;
  /*     background-image: linear-gradient(90deg, #fcecfe, #fbf6e7, #e6fcf5); */
  background-image: linear-gradient(
      315deg,
      #ffc4ec -10%,
      #efdbfd 50%,
      #ffedd6 110%
  );
  padding: .8em 1.4em;
  position: relative;
  isolation: isolate;
  box-shadow: 0 2px 3px 1px hsl(var(--glow-hue) 50% 20% / 50%), inset 0 -10px 20px -10px hsla(var(--shadow-hue),10%,90%,95%);
  border-radius: 0.66em;
  border-width: 0.1px;
  scale: 1;
  transition: all var(--spring-duration) var(--spring-easing);
  cursor: pointer;
}

.waitlist-button:hover:not(:active),
.waitlist-button.active {
  transition-duration: calc(var(--spring-duration)*0.5);
  scale: 1.05;
  box-shadow: 0 4px 8px -2px hsl(var(--glow-hue) 50% 20% / 50%), inset 0 0 0 transparent;
}
.waitlist-button:active {
  scale: 1;
  transition-duration: calc(var(--spring-duration)*0.5);
}

.waitlist-shimmer {
  position: absolute;
  inset: -40px;
  border-radius: inherit;
  mask-image: conic-gradient(
      from var(--waitlist-shimmer, 0deg),
      transparent 0%,
      transparent 10%,
      black 36%,
      black 45%,
      transparent 50%,
      transparent 60%,
      black 85%,
      black 95%,
      transparent 100%
  );
  mask-size: cover;
  mix-blend-mode: plus-lighter;
  animation: waitlist-shimmer 1s linear infinite both;
}
.waitlist-button:hover .waitlist-shimmer::before,
.waitlist-button:hover .waitlist-shimmer::after,
.waitlist-button.active .waitlist-shimmer::before,
.waitlist-button.active .waitlist-shimmer::after {
  opacity: 1;
  animation: shine 1.2s ease-in 1 forwards;
}
.waitlist-shimmer::before,
.waitlist-shimmer::after {
  transition: all 0.5s ease;
  opacity: 0;
  content: "";
  border-radius: inherit;
  position: absolute;
  mix-blend-mode: color;
  inset: 40px;
  pointer-events: none;
}
.waitlist-shimmer::before {
  box-shadow: 0 0 3px 2px hsl(var(--glow-hue) 20% 95%),
      0 0 7px 4px hsl(var(--glow-hue) 20% 80%),
      0 0 13px 4px hsl(var(--glow-hue) 50% 70%),
      0 0 25px 5px hsl(var(--glow-hue) 100% 70%);
  z-index: -1;
}

.waitlist-shimmer::after {
  box-shadow: inset 0 0 0 1px hsl(var(--glow-hue) 70% 95%),
      inset 0 0 2px 1px hsl(var(--glow-hue) 100% 80%),
      inset 0 0 5px 2px hsl(var(--glow-hue) 100% 70%);
  z-index: 2;
}

.waitlist-button .waitlist-text {
  color: transparent;
  background-clip: text;
  background-color: var(--bg);
  background-image: linear-gradient(120deg, transparent, hsla(var(--glow-hue),100%,80%,0.66) 40%, hsla(var(--glow-hue),100%,90%,.9) 50%, transparent 52%);
  background-repeat: no-repeat;
  background-size: 300% 300%;
  background-position: center 200%;
}

.waitlist-button:hover .text,
.waitlist-button.active .text {
  animation: text .66s ease-in 1 both;
}

@keyframes progress-shimmer {
  0% {
      background-position: -1000px 0;
  }
  100% {
      background-position: 1000px 0;
  }
}

.progress-bar-shimmer {
    background: linear-gradient(
        90deg,
        #4CAF50 0%,
        #81c784 20%,
        #e8f5e9 50%,
        #81c784 80%,
        #4CAF50 100%
    );
    background-size: 1000px 100%;
    animation: progress-shimmer 1.5s infinite linear;
    position: relative;
    overflow: hidden;
}

/* Add this for an extra shine effect */
.progress-bar-shimmer::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        transparent 0%,
        rgba(255, 255, 255, 0.4) 50%,
        transparent 100%
    );
    animation: progress-shimmer 0.8s infinite linear;
}

.animated-transition-gradient-text {
  transition: opacity 0.5s ease-in-out;
  animation: fadeEffect 0.5s ease-in-out;
}

@keyframes fadeEffect {
  0% {
      opacity: 0;
      transform: translateY(0px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

/* Add this class for the exit animation */
.fade-out {
  opacity: 0;
  transform: translateY(0px);
}

.text-wrapper-merra {
  display: inline-block;
  /* position: relative; */
}

.letter {
  display: inline-block;
  transition: all 0.5s ease-in-out;
  opacity: 1;
}

.morphing {
  transition: all 0.5s ease-in-out;
}

.fade {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: 0;
}

.appear {
  animation: slideIn 0.5s ease-in-out;
  opacity: 0;
  animation-fill-mode: forwards;
}

@keyframes slideIn {
  from {
      opacity: 0;
      transform: translateX(-20px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

/* Optional: Add some hover effects */
.text-wrapper-merra:hover .letter {
  color: #4a90e2;
  transform: translateY(-2px);
  transition-delay: calc(0.1s * var(--index));
}

/*Safari and Chrome*/
input[type="range"] {
  -webkit-appearance: none;
  appearance: none; 
  cursor: pointer;
  overflow: hidden;
  height: 10px;
  border-radius: 1rem;
  border: 2px solid black;
  background: black;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: black;
  height: 10px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none; 
  height: 10px;
  width: 10px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #ff4d97;
  box-shadow: -30rem 0 2rem 25rem #AB5898,
              -25.5rem 0 0 25rem  #5C4BD3;
}

/* Add active state */
input[type="range"]::-webkit-slider-thumb:active {
  background-color: #ffffff80; /* Or any color you prefer */
  transform: scale(1); /* Optional: make it slightly larger when active */
}

.tick-slider-container {
  position: relative;
  width: 100%;
}

.tick-marks {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  /* padding: 0 10%; */
  pointer-events: none;
  margin-left: 3px;
  margin-right: 3px;
}

.tick {
  width: 2px;
  height: 3px;
  background: #fff;
  position: relative;
  top: -11px;
}

.partial-analysis-banner {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 0.9em;
  color: #856404;
}

.response-text.partial {
  border-left: 3px solid #ffc107;
  padding-left: 15px;
}

.url-input:focus {
  outline: none;
  border-color: #4285f4;
  box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.2);
}

.url-input.error {
  border-color: #dc3545;
  margin: 0px;
  text-align: left;
}

.url-input.error:focus {
  box-shadow: 0 0 0 2px rgba(220, 53, 69, 0.2);
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 5px;
  height: 5px;
  border: 1.5px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.red-delete-button:hover {
  filter: brightness(0.95);
}

.prospect-input-container {
  margin-bottom: 2rem;
  width: 100%;
}

.input-row {
  display: flex;
  gap: 20px;
  align-items: center;
}

.url-input-container {
  flex: 1;
}

.screenshot-upload-container {
  flex: 1;
}

.screenshot-preview {
  position: relative;
  display: inline-block;
}

.remove-screenshot {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #ff4444;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.remove-screenshot:hover {
  background: #cc0000;
}

.add-prospect-button {
  min-width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-border-large {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1.5px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

/* Button hover styles */
.red-delete-button:hover {
  filter: brightness(0.95);
}

.download-button:hover {
  filter: brightness(0.95);
}

.purple-button:hover {
  filter: brightness(0.95);
}

.pink-button:hover {
  filter: brightness(0.95);
}


.gradient-button {
  background: linear-gradient(90deg, #AB5898 0%, #5C4BD3 100%);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  transition: opacity 0.3s ease;
}

.gradient-button:hover {
  opacity: 0.9;
}

/* Option: Ajouter un effet de brillance au survol */
.gradient-button {
  position: relative;
  overflow: hidden;
}

.gradient-button::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(45deg);
  transition: all 0.3s ease;
  opacity: 0;
}

.gradient-button:hover::after {
  opacity: 1;
}

.code {
  font-size: 3rem;
  display: flex;
  flex-wrap: nowrap;
  color: hsl(0 0% 100%);
  border-radius: 1rem;
  background: hsl(0 0% 6%);
  justify-content: center;
  box-shadow: 0 1px hsl(0 0% 100% / 0.25) inset;
}

.code:hover {
  cursor: grab;
}

.digit {
  display: flex;
  height: 100%;
  padding: 5.5rem 1rem;
  font-size: 40px;
}

.digit:focus-visible {
  outline-color: hsl(0 0% 50% / 0.25);
  outline-offset: 1rem;
}

.digit span {
  scale: calc(var(--active, 0) + 0.5);
  filter: blur(calc((1 - var(--active, 0)) * 1rem));
  transition: scale calc(((1 - var(--active, 0)) + 0.2) * 1s), filter calc(((1 - var(--active, 0)) + 0.2) * 1s);
}

ul {
  padding: 0;
  margin: 0;
}

.digit:first-of-type {
  padding-left: 5rem;
}
.digit:last-of-type {
  padding-right: 5rem;
}

:root {
  --lerp-0: 1; /* === sin(90deg) */
  --lerp-1: calc(sin(50deg));
  --lerp-2: calc(sin(45deg));
  --lerp-3: calc(sin(35deg));
  --lerp-4: calc(sin(25deg));
  --lerp-5: calc(sin(15deg));
}

.digit:is(:hover, :focus-visible) {
  --active: var(--lerp-0);
}
.digit:is(:hover, :focus-visible) + .digit,
.digit:has(+ .digit:is(:hover, :focus-visible)) {
  --active: var(--lerp-1);
}
.digit:is(:hover, :focus-visible) + .digit + .digit,
.digit:has(+ .digit + .digit:is(:hover, :focus-visible)) {
  --active: var(--lerp-2);
}
.digit:is(:hover, :focus-visible) + .digit + .digit + .digit,
.digit:has(+ .digit + .digit + .digit:is(:hover, :focus-visible)) {
  --active: var(--lerp-3);
}
.digit:is(:hover, :focus-visible) + .digit + .digit + .digit + .digit,
.digit:has(+ .digit + .digit + .digit + .digit:is(:hover, :focus-visible)) {
  --active: var(--lerp-4);
}
.digit:is(:hover, :focus-visible) + .digit + .digit + .digit + .digit + .digit,
.digit:has(+ .digit + .digit + .digit + .digit + .digit:is(:hover, :focus-visible)) {
  --active: var(--lerp-5);
}



.scroll {
  height: 50px; /* Remplace $size */
  width: 30px;  /* Remplace $size * .6 */
  border-radius: 50px;
  border: 2.5px solid #66666695; /* Remplace $size/20 solid $color */
  position: relative;
}

.scroll:before {
  content: '';
  position: absolute;
  left: calc(50% - 2.5px); /* Remplace calc(50% - #{$size} / 20) */
  top: 10px;  /* Remplace $size/5 */
  height: 5px; /* Remplace $size/10 */
  width: 5px;  /* Remplace $size/10 */
  background: #66666695; /* Remplace $color */
  border-radius: 50%;
  animation: scroll 1.8s infinite;
}

@keyframes scroll {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
    transform: translateY(0);
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(12.5px); /* Remplace $size/4 */
  }
}

.counter-section {
  margin: 20px 0;
  text-align: center;
}

.input-section {
  margin: 20px 0;
}

.percentage-section {
  margin: 20px 0;
  text-align: center;
}

.input-field {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
}

.progress-bar-container {
  width: 80%;
  height: 20px;
  background-color: #2A2B2F;
  border-radius: 10px;
  margin: 20px auto;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  border-radius: 10px;
  transition: width 0.3s ease-in-out;
}